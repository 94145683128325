import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

class Contact extends Component{
    constructor(props)
    {
        super(props);
        this.state={
            firstName:"",
            lastName:"",
            email:"",
            phoneNumber:"",
            selectOption:"choose",
            radioOption:"",
            comments:""
        }
    }
    changeFirstName = (event) =>{
        this.setState({
            firstName:event.target.value
        })
    }

    changeLastName = (event) =>{
        this.setState({
            lastName:event.target.value
        })
    }

    changeEmail = (event) =>{
        this.setState({
            email:event.target.value
        })
    }

    changePhoneNumber = (event) =>{
        this.setState({
            phoneNumber:event.target.value
        })
    }

    changeSelectOption = (event) =>{
        this.setState({
            selectOption:event.target.value
        })
    }
    changeRadioOption = (event) =>{
        this.setState({
            radioOption:event.target.value
        })
    }

    changeComments = (event) =>{
        this.setState({
            comments:event.target.value
        })
    }

    submitHandler = async (event) => {
        event.preventDefault();
        // console.log(this.state);
        const {firstName,lastName,email,phoneNumber,selectOption,radioOption,comments}=this.state;
        const form = await axios.post('http://localhost:3001/api/form',{
            firstName,
            lastName,
            email,
            phoneNumber,
            selectOption,
            radioOption,
            comments
        })
    }

    render(){
        window.scrollTo(0,0);
        return(
            <body>
         {/* Contact Page */}
         <div className="contact-page">
             <div className="container">
                 <div className="row">
                     <div className="col-md-8">
                            <h2>Contact Us!</h2> 
                         <form onSubmit={this.submitHandler}>
                               <div className="form-row">

                                 <div className="form-group col-md-6">
                                       <input type="text" className="form-control" 
                                       placeholder="First name" value ={this.state.value}
                                       onChange={this.changeFirstName} />
                                 </div>

                                 <div className="form-group col-md-6">
                                       <input type="text" className="form-control"
                                        placeholder="Last name" value={this.state.value}
                                        onChange={this.changeLastName} />
                                 </div>

                               </div>
                               <div className="form-group">
                                  <input type="email" className="form-control" 
                                    id="inputEmail4" placeholder="Email" value={this.state.value}
                                    onChange={this.changeEmail} />
                                </div>

                               <div className="form-group">
                                 <input type="text" className="form-control"
                                 placeholder="Phone Number" value={this.state.value} 
                                 onChange={this.changePhoneNumber} />
                               </div>

                               <div className="form-group">
                                   <label className="form-check-label">I'm having an issue with my *</label>
                                 <select className="form-control" value={this.state.value}
                                 onChange={this.changeSelectOption}>
                                      <option>Choose</option>
                                      <option>Hip</option>
                                      <option>Both Hips</option>
                                      <option>Knee</option>
                                      <option>Both Knees</option>
                                 </select>
                             </div>	
                             <div className="form-row">
                                 <div className="form-group col-md-5">
                                     <label className="form-check-label">Are You Currently a Patient? *</label>
                                 </div>	
                                 <div className="form-group col-md-7">	
                                     <div className="custom-control custom-radio custom-control-inline">

                                           <input type="radio" id="customRadioInline1" 
                                           name="radioOption" 
                                           className="custom-control-input"
                                           value="Yes"
                                           checked={this.state.radioOption==="Yes"}
                                           onChange={this.changeRadioOption} />
                                           <label className="custom-control-label" for="customRadioInline1">Yes</label>
                                     </div>

                                     <div className="custom-control custom-radio custom-control-inline">
                                         
                                           <input type="radio" id="customRadioInline2"
                                            name="radioOption" 
                                            className="custom-control-input"
                                            value="No"
                                            checked={this.state.radioOption==="No"}
                                            onChange={this.changeRadioOption}/>
                                           <label className="custom-control-label" for="customRadioInline2">No</label>
                                     </div>
                                 </div>	
                             </div>

                               <div className="form-group">
                                 <label for="exampleFormControlTextarea1">Comment or Message</label>
                                 <textarea className="form-control" id="exampleFormControlTextarea1" rows="4"
                                 value={this.state.value} onChange={this.changeComments}></textarea>
                               </div>	

                               <button type="submit" className="btn btn-primary mb-2">Submit</button>
                         </form>					
                     </div>
                     <div className="col-md-4 sidebar-section">
                         <h3>Our Locations</h3>
                         <div className="mb-singlepost-img">
							<img src="/img/location.jpg" className="img-fluid img-thumbnail" alt="Keep Here Post Title"/>
						 </div>
                         <div className="location">
                             <h5><Link to="/">Hip & Knee Center BD</Link></h5>
                             <p><strong>167/B, Green Road,</strong> <br/>Dhanmondi, Dhaka-1209, <br/>Bangladesh <br/><a href="tel:+8801686-640449">01686-640449</a></p>
                             <p><strong>APPOINMENT</strong><br/><a href="tel:+8801686640449">01686-640449</a></p>
                         </div>
                     </div>
                 </div>
             </div>
         </div>	
       </body>
        );
    }
}

export default Contact;