import React,{Component} from 'react';
import {Link} from 'react-router-dom';


class Navigation extends Component{
     constructor(props){
          super(props);
          this.state={
               dropdownIsOpen:false
          }
          this.toggleDropDown = this.toggleDropDown.bind(this);
     }

     toggleDropDown=()=>{
          this.setState({
               dropdownIsOpen:!this.state.dropdownIsOpen,
          });
     }

    render(){
        return(
               <nav className="navbar navbar-expand-lg sticky-top">
               <div className="container">
                    <Link className="navbar-brand" to="/"><img src="img/logo.png" alt="Logo"/></Link>
                    <button className="navbar-toggler toggler-example" type="button" 
                    data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" 
                    aria-expanded="false" aria-label="Toggle navigation"
                    onClick = {this.toggleDropDown}
                    >

                    <span className="dark-blue-text">
                    <i className="fa fa-bars fa-1x" aria-hidden="true">
                    </i></span>
                    </button>
                    <Toggle/>
                         
               </div>
          </nav>
        );
    }
}

function Toggle(props){
     return(
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
               <ul className="navbar-nav mr-auto order-lg-1">
                    <li className="nav-item active">
                    <Link className="nav-link" to="/">Home<span className="sr-only">(current)</span></Link>
                    </li>
                    <li className="nav-item">
                         <Link className="nav-link" to="/blogs">Blog</Link>
                    </li>
                    <li className="nav-item">
                         <Link className="nav-link" to="/aboutus">About Us</Link>
                    </li>
                    <li className="nav-item">
                         <Link className="nav-link" to="/contact">Contact Us</Link>
                    </li>

                    <div className="header-cb">
                         <a href="tel:+8801686-640449"><i className="fa fa-phone" aria-hidden="true"></i><span>01686-640449</span></a>
                    </div>
               </ul>
          </div>
     )
}

export default Navigation;
 