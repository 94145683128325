import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import ContactForm from './contactform';

class Blog extends Component{
    render(){
        return(
        <body> 
			{/* Doctor Part */}
			<div className="doctor-part">
				<div className="container">
					<div className="row">
						{/* Main Blog Section */}
						<div className="col-lg-8">
							<div className="main-blog">
								<h2>Hip & Knee Center Latest Article</h2>
								{/* Main Blog Single Post */}
								<div className="mb-singlepost">
									<div className="row">
										<div className="col-md-5">
											<div className="mb-singlepost-img">
												<img src="img/kneeArthoscopy.jpg" className="img-fluid img-thumbnail" alt="Keep Here Post Title"/>
											</div>
										</div>
										<div className="col-md-7">
											<div className="mb-singlepost-content">
												<h3><Link to="/blog/knee-arthroscopy">Knee Arthroscopy Preparation & Benefits: Get Relief From Long Time Pain</Link></h3>
												<div className="mb-singlepost-meta">
													<span className="mbs-author">Posted by <Link to="/">HipKneeCenter</Link></span>
													<span className="mb-cat"><Link to="/blog/knee-arthroscopy">Knee Arthroscopy</Link></span>
													{/* <span>October 10, 2019</span> */}
												</div>
												<div className="mb-singlepost-text">
													<p>Bilateral non cemented total hip arthroplasty done on on 22.03.17 for sero positive arthritis with bilateral AVN and left sided protrusio acetabuli.Post operatively patient recovering very smoothly.</p>
													<Link to="/blog/knee-arthroscopy">Read More</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* Main Blog Single Post */}
								<div className="mb-singlepost">
									<div className="row">
										<div className="col-md-5">
											<div className="mb-singlepost-img">
												<img src="/img/hipArthoscopy.jpg" className="img-fluid img-thumbnail" alt="Keep Here Post Title"/>
											</div>
										</div>
										<div className="col-md-7">
											<div className="mb-singlepost-content">
											<h3><Link to="/blog/hip-arthroscopy">Hip Arthroscopy: Ultimate solution for long time painful hip joint</Link></h3>
												<div className="mb-singlepost-meta">
													<span className="mbs-author">Posted by <Link to="/">HipKneeCenter</Link></span>
													<span className="mb-cat"><Link to="/blog/hip-arthroscopy">Hip Arthroscopy</Link></span>
													{/* <span>October 10, 2019</span> */}
												</div>
												<div className="mb-singlepost-text">
													<p>Bilateral non cemented total hip arthroplasty done on on 22.03.17 for sero positive arthritis with bilateral AVN and left sided protrusio acetabuli.Post operatively patient recovering very smoothly.</p>
													<Link to="/blog/hip-arthroscopy">Read More</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* Main Blog Single Post */}
								<div className="mb-singlepost">
									<div className="row">
										<div className="col-md-5">
											<div className="mb-singlepost-img">
												<img src="/img/hipArthroscopyRecoveryTipsArthroscopySpecialist.jpg" className="img-fluid img-thumbnail" alt="Keep Here Post Title"/>
											</div>
										</div>
										<div className="col-md-7">
											<div className="mb-singlepost-content">
												<h3><Link to="/blog/hip-arthroscopy-surgery">Hip Arthroscopy Surgery Process, Benefits & Recovery: Tips by Arthroscopy Specialist</Link></h3>
												<div className="mb-singlepost-meta">
													<span className="mbs-author">Posted by <Link to="/">HipKneeCenter</Link></span>
													<span className="mb-cat"><Link to="/blog/hip-arthroscopy-surgery">Hip Arthroscopy Surgery</Link></span>
													{/* <span>October 10, 2019</span> */}
												</div>
												<div className="mb-singlepost-text">
													<p>Bilateral non cemented total hip arthroplasty done on on 22.03.17 for sero positive arthritis with bilateral AVN and left sided protrusio acetabuli.Post operatively patient recovering very smoothly.</p>
													<Link to="/blog/hip-arthroscopy-surgery">Read More</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* Main Blog Single Post */}
								<div className="mb-singlepost">
									<div className="row">
										<div className="col-md-5">
											<div className="mb-singlepost-img">
												<img src="/img/exercise.jpg" className="img-fluid img-thumbnail" alt="Keep Here Post Title"/>
											</div>
										</div>
										<div className="col-md-7">
											<div className="mb-singlepost-content">
											<h3><Link to="/blog/hip-exercise">7 Best Hip Exercise: Get Physical Hip therapies yourself</Link></h3>
												<div className="mb-singlepost-meta">
													<span className="mbs-author">Posted by <Link to="/">HipKneeCenter</Link></span>
													<span className="mb-cat"><Link to="/blog/hip-exercise">Hip Exercise</Link></span>
													{/* <span>October 10, 2019</span> */}
												</div>
												<div className="mb-singlepost-text">
													<p>Bilateral non cemented total hip arthroplasty done on on 22.03.17 for sero positive arthritis with bilateral AVN and left sided protrusio acetabuli.Post operatively patient recovering very smoothly.</p>
													<Link to="/blog/hip-exercise">Read More</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* Blog Page Sidebar Section */}
						<div className="col-lg-4 dr-sidebar">
							<div className="ac-card">
								<h4>SCHEDULE AN APPOINTMENT</h4>
								<ContactForm/>							
							</div>
							{/* <div className="drs-sidebar">
								<h3>Our Services</h3>
								<p><a href="#">Anterior Approach Hip Replacement</a></p>
								<p><a href="#">Total Knee Replacement</a></p>
								<p><a href="#">Advantages of Outpatient Surgery</a></p>
								<p><a href="#">Educational Videos</a></p>
							</div> */}
						</div>
					</div>
					<div className="drintro">
						<div className="row">
							<div className="col-md-3">
								<img src="img/Dr.farid.jpg" className="img-fluid img-thumbnail rounded-circle" alt="Responsive image"/>
							</div>
							<div className="col-md-9">
								<h4>Dr. AM Farid Uddin Ahmed</h4>
								<span>ORTHOPLASTY & ORTHOSCOPY SURGEON</span>
								<p>Having graduated from Medicine in 1999, Dr. A. M. Forid Uddin Ahmed has pursued his career in the field of orthopedics and completed his fellowship in general surgery in 2007 from Bangladesh College of Physicians & Surgeons (BCPS) & MS in orthopaedic surgery from National Institute of Traumatology and Orthopedic Rehabilitation under Bangabandhu Sheikh Mujib Medical University (BSMMU) in 2009. Throughout carrier, he has relentlessly devoted his time and efforts to serve the orthopedic surgery.</p>
							</div>							
						</div>
					</div>			
				</div>
			</div>
		</body>
		);
    }
}

export default Blog;