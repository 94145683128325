import React,{Component} from 'react';
import {Link} from 'react-router-dom';

export default class HipContent extends Component{
    render()
    {
        window.scrollTo(0,0);
        return(
            <div>
                <p><strong>What is hip Arthroscopy?</strong></p>
                <p>Innovation in medical science is growing day by day. Hip arthroscopy is such a modern form of surgery. Hip arthroscopy is performed if the hip joint gets affected or needs to replace or any spine is tore. Developed country has a great practice of hip arthroscopy. Hip arthroscopy is referred when regular treatment fails to decrease joint pains. This surgery was invented in 1960&rsquo;s. Since then, wide practices and its effectiveness created great demand in the field of medical science.</p>
                <p><strong>Why do we feel hip Pain?</strong></p>
                <p>You may have hip pain due to several reasons. A few are as described below:-</p>
                <ul>
                    <li>Older people suffer from hip pain the most. With the growing age, their cartilage fails to protect the hip joint bones and it results intolerable pains. In medical term this situation is called <a href="https://www.mayoclinic.org/diseases-conditions/osteoarthritis/symptoms-causes/syc-20351925">Osteoarthritis</a>.</li>
                    <li>Any sort of hip injury or dislocation of joint can also leads to massive pain. Injuries can create blood crisis and result arthritis or pain. This is also known as <a href="https://en.wikipedia.org/wiki/Avascular_necrosis">Avascular necrosis</a>.</li>
                    <li>Rheumatoid Arthritis is a very common term. In this situation, our cartilage gets damaged as the membrane becomes thickened and veil.</li>
                    <li>Those who had hip issues in their childhood may also suffer from pains. They might have cured that time but it still may exist because of the abnormal growth of hip.</li>
                </ul>
                <p><strong>When Should I prefer hip Arthroplasty?</strong></p>
                <p>These days, most of the patients prefer arthroplasty rather than going for any major surgery. Arthroplasty is easy yet needs to be done very carefully. Not all the doctors or hospital offers arthroplasty. So, if you are in excessive hip pain, you shouldn&rsquo;t be late to consult with an arthroscopy specialist. Your doctor will best describe you in details about arthroscopy. Likewise every other surgery, arthroscopy also have some pre-post preparation. During your consultation, doctor will let you know about recovery process, details about surgery, and other related facts.</p>
                <p>From now on arthroplasy is also possible in Bangladesh. You don&rsquo;t have to worry about your visa or ticket. <strong><a href="https://hipkneecenterbd.com">Hip &amp; Knee Center BD</a></strong> is located at the middle of Dhaka city. For past few years, They have been able to successfully cure thousands of patients who had been suffering from severe pains. <strong>Hip &amp; knee center</strong> is run by highly qualified doctor like <strong>DR. AM Farid Uddin Ahmed</strong>. He has a vast knowledge on arthroscopy. He has achieved both local and international recognition for his contribution in the field of arthroplasty.&nbsp; Does this information ease your pain? Yet if not, please visit the following address for fast relief.</p>
                <p><strong>Comfort Tower, 1167/B,Green Road, Dhanmondi, Dhaka, Bangladesh. Or confirm your appointment at the following Mobile Number: 01686-640449. You can also Email: <a href="mailto:fuahmed2007@yahoo.com">fuahmed2007@yahoo.com</a></strong></p>
                <p><strong>Opens on: Tuesday-(8 AM-4 PM)&nbsp;</strong></p>
                <p><strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Thursday-(8 AM-11 PM)</strong></p>
                <p><strong>Preparation of Hip Replacement </strong></p>
                <p>Any kind of surgery requires some pre consideration for its safety. Hip replacement also needs some basic perpetrations as followed:-</p>
                <ul>
                    <li>Firstly, you should have deep understanding from your surgeon about arthroplasty and its procedure.</li>
                    <li>Then you must know the medication process.</li>
                    <li>You may do some tests such as urine, weight, physical and skin test.</li>
                    <li>Arthroplasty is not a major surgery maybe though you ought to arrange blood for you or if required surgeon will let you know.</li>
                    <li>If you are socially active, then you should also discuss with your doctor about how you can be able to maintain your social activities after arthroscopy.</li>
                </ul>
                <p><strong>Hip Replacement Process:</strong></p>
                <p>Arthroscopy is a minor surgery. It is very simple yet sensitive. So make sure that you are at right hands for this. Initially, the surgeon will numb you applying anesthesia. Generally there are three anesthesias available during any kind of surgery. Firstly, <strong>local anesthesia</strong> is some medicine that will keep you awake but numb. You can see everything but can&rsquo;t feel anything at all. Secondly, doctor will inject some medicine into your vain and make you numb. This is also known as regional anesthesia. Thirdly, general anesthesia that make you asleep throughout the operation. All the major operations are made using this type of anesthesia. Your doctor will decide the anesthesia evaluating your overall condition and complexity of the surgery.</p>
                <p>&nbsp;</p>
                <div className="sp-img">
                    <img src="/img/hip2image.jpg" className="img-fluid" alt="Responsive image" />
                </div>
                <p>&nbsp;</p>
                <p>The next process is to create small reap so that the doctor may insert arthroscopy or small camera inside. Then the doctor will have visual condition of your joints through the screen connected with arthroscopy. If the doctor feels that repair might get you relief of the pain he will do that. Or if the doctor feels to replace anything with artificial prosthesis then he/she will do that with the help of arthroscopy. Usually, there are two types of prosthesis. The surgical cement is used to connect bones in cemented prosthesis. Bones are attached in uncemented prosthesis with perforated surface. This surface allows hip to grow normally.&nbsp; Finally the surgery will end up with stitch those reap and sterile bandage.</p>
                <p><strong>Hip Replacement Recovery </strong></p>
                <p>It is very important for you to maintain proper recovery acts as prescribed by your surgeon. It takes 2-3 months to recover fully. Immediately after your Arthroplasty Surgery you may be prescribed some pain killers to control your pain.&nbsp; You may also be given compression boot while moving to be free of blood clots. The other important way of recovery is physical therapy.</p>
                <p>&nbsp;</p>
                <p>Only your surgeon can prescribe your physical therapy course in according to your condition. These PT may include some very basic exercise to strengthen your vain and muscle. You should also visit your doctor in a periodic term to follow up your condition unless you are able to move as usual.</p>
                <p>Instead of major surgery, hip replacement is better to get rid of excessive hip joint problems. It is indeed a great scientific solution.</p>
            </div>
        );
    }
}