import React,{Component} from 'react';
import {Link} from 'react-router-dom';

export default class ImportantExercise extends Component{
    render(){
        window.scrollTo(0,0);
        return(
            <div>
                <p><strong>What is Hip Pain?</strong></p>
                <p>Exercise is the best way to keep our body fit and strong. Hip exercise is also essential for <a href="https://www.webmd.com/pain-management/guide/hip-pain-causes-and-treatment">Hip Pain</a>. Our hip is called ball-and-socket joint yet the strongest joint of our body. But often we suffer from hip pain and look for solution. Hip pain may cause in different situation. If we feel pain in our inner joints of hip that means any abnormality took place on our hip joint. Pain on the outside of our hip, may cause if any of the ligament, tendons, muscles, or soft tissues are being affected. Other than these, we may encounter hip pain as the side effect of any conditions or diseases. This situation is called referred pain. Likewise several reasons, there are several solutions as well. Hip exercise is one of them. You will learn a lot about hip exercise throughout this article.</p>
                <p><strong>Which is better in between Hip surgery and Hip therapy?</strong></p>
                <p>Both the term hip surgery and hip therapy are important but the implication may differ on the condition and effectiveness. Hip surgery is referred in terms of complexity of the <a href="https://emedicine.medscape.com/article/1898964-overview">Internal Joints</a>. On the other hand, hip therapy is referred when doctor feels the pain can be replaced by some basic physical exercises rather than surgery. At times, hip therapy is prescribed as the recovery process of hip replacement or surgery.&nbsp;</p>
                <p><strong>What are the best Exercises that can rid me out of hip pain?</strong></p>
                <p>Now I&rsquo;m going to discuss about <strong>7 best exercises for your hip pain</strong>. You can do it at your home with the guidance of any hip surgeon or specialists.</p>
                <p>Among these 7 exercises, first 2 forms are to stretch the muscles surround our hip joints. Rest 5 form of exercises is to strengthen your hip muscles. &nbsp;So read out and practice the following exercises regularly if you are suffering from hip pain for long time:-</p>
                <p><strong>Hip exercises to stretch your muscles around hip joints: </strong>the following two exercise forms are very effective to stretch your muscles around hip joint. After a warm shower, our muscles get relaxed and that&rsquo;s the best time to free your muscles from stiffness:-</p>
                <ul>
                    <li><strong>Hip and lower back stretch: </strong>To perform hip and lower back stretch you have to lay down flat on the floor. You can use matt or you can do it on the floor. Now you have to bend your knee towards your body. You have to make sure that your feet are flat as well. Now pull your knees towards your chest using both hands. Take a deep breath while pulling your knees towards your shoulder. Stretch as long as it&rsquo;s flexible for you and hold the position for around 30 seconds. Then breathe normally.</li>
                    <li><strong>Hip flexion &amp; Hip extension: </strong>stand upright and put your both hands on the waist. Now slowly rise up your knee as long as you feel comfortable towards your hip level. While doing this be sure that your other leg is straight as well. Hold this position for few seconds before repeat the same with another leg. Try to do this repetition for 10-12 times.</li>
                </ul>
                <p>For hip extension you can take support of any hard surface like, wall, chair, table or anything. Then you have to hold the surface of chair or that stable object. Make sure that your shoulders are straight. Now lift your first leg backward unless your buttock feel clinch. Hold the position and repeat the same with your other leg. Do this for 10-12 times as well.</p>
                <p><strong>Hip exercises to strengthen your hip muscles: </strong>The following exercises are very effective in terms of strengthening your hip muscles. These exercises are also called resistance exercise as these forms of exercises can give you relief of your temporary hip pain.</p>
                <ul>
                    <li><strong>Heel-to-buttock exercise: </strong>stand straight and upright. Your shoulders should be straight as well. If you want you can take support of chair, table or wall. Now bend your knee backward and bring the heel up to your buttock. Your knee position should be aligned the same as your other leg. To make your body stable, you can hold your ankle with supporting hands for seconds while lifting up. Slowly put down your leg and keep doing the same for 10-12 times. Once you are done with first leg repeat the whole process with the second one.</li>
                    <li><strong>External Hip Rotation: </strong>there are several forms of external hip rotation but I&rsquo;m going to share best three of them:-</li>
                    <li><strong>Calmshell: </strong>Lie on your left side and make your left arm support your head. Now lift up your leg straight as much as you can and hold for seconds. While lifting and bringing back your leg make sure that your toe is rotating in and out.</li>
                    <li><strong>Lying on stomach: </strong>for doing this lie on your stomach and bend your first leg on 90 degree position. Now slowly swing it left and right depending on your comfort level and put down. Repeat this 10-12 times for both legs.</li>
                    <li><strong>Knee to chest stretch: </strong>lie straight on the floor and bend your knee. Now hold your knee with palms of your hand and pull up to your chest and hold for seconds. Repeat this 10-12 times for other leg.</li>
                    <li><strong>Double hip Rotation: </strong>straightly lay down on floor. Now put your legs together and bend it until you feel any discomfort. Now slowly swing your knee left and your head opposite. Try to take your knees as close as you can towards the floor. Hold this for few seconds and move to other side doing the same.</li>
                    <li><strong>Mini Squat: </strong>This is very simple but effective exercise. Stand upright and slowly sit down until your knees go over toes. Hold the position for few seconds and slowly back straight to previous position. Keep doing this for 10-12 times.</li>
                    <li><strong>TChair Stand: </strong>take a chair first and make sure it remains stable throughout the exercise. Hold your shoulders using opposite hand. Now slowly sit down until your buttock touches the edge of the chair. Hold the position for second and move back to standing position. Repeat this for 10-12 times.</li>
                </ul>
                <p>In Bangladesh we also have such hip specialist who offers dynamic and easy hip pain solution. <strong>Dr. AM Farid Uddin Ahmed</strong> is one of them. He is serving the hip patients for last few years. With modern treatment and his efficiency he has cut the attention of patients towards his <strong>Hip &amp; Knee Center BD</strong>. You can also reach to <strong>Dr. AM Farid Uddin Ahmed</strong> by following address:-</p>
                <p><strong>Comfort Tower, 1167/B,Green Road, Dhanmondi, Dhaka, Bangladesh. Or confirm your appointment at the following Mobile Number: 01686-640449. You can also Email: <a href="mailto:fuahmed2007@yahoo.com">fuahmed2007@yahoo.com</a></strong></p>
                <p><strong>Opens at: Tuesday-(8 AM-4 PM)</strong></p>
                <p><strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Thursday-(8 AM-11 PM)</strong></p>
                <p><strong>Website: </strong><a href="https://www.hipkneecenterbd.com/">https://www.hipkneecenterbd.com</a></p>
            </div>
        );
    }
}