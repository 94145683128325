import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import MayLike from './maylike';
import AppoinmentForm from './appoinmentform';
import SocialMedia from './socialmedia.js';
import KneeContent from './kneecontent';

export default class Knee extends Component{
    render(){
        return(
<body>
	{/* Doctor Part */}
	<div className="doctor-part">
		<div className="container">
			<div className="row">
				{/* Main Blog Section */}
				<div className="col-lg-8">
					<div className="single-blog">
						{/* BreadCrumb */}	
						<nav aria-label="breadcrumb" className="hidden-sm-xs">
						  	<ol className="breadcrumb">
						    	<li className="breadcrumb-item"><Link to="/blogs">Blog</Link></li>
						    	<li className="breadcrumb-item active" aria-current="page">Knee Arthroscopy Preparation & Benefits: Get Relief From Long Time Pain</li>
						  	</ol>
						</nav>	
						{/*Post Title & Meta*/}
						<h2>Knee Arthroscopy Preparation & Benefits: Get Relief From Long Time Pain</h2>
						<div className="mb-singlepost-meta">
							<span className="mbs-author">Posted by <Link to="/">HipKneeCenter</Link></span>
							<span className="mb-cat"><Link to="/blog/knee-arthroscopy">Knee Arthroscopy</Link></span>
							{/* <span>january 27, 2020</span> */}
						</div>
						<div className="sp-img">
							<img src="/img/kneeArthoscopy.jpg" className="img-fluid" alt="Responsive image" />
						</div>
						<KneeContent />
                        <SocialMedia />
                        <MayLike />
					</div>
				</div>
                <AppoinmentForm />
			</div>		
		</div>
	</div>
  </body>
        );
    }
}
