import React,{Component} from 'react';
import {Link} from 'react-router-dom';

export default class Content1 extends Component{
    render(){
        window.scrollTo(0,0);
        return(
            <div className="sp-content">
                <h2>Definition of Knee Arthroscopy</h2>
                <p>Arthroscopy is for of surgery performed by any specialist with the help of some micro equipment. <strong>Knee arthroscopy</strong> is a part of this surgical segment. In this surgery your doctor will try to treat your affected <a href="https://en.wikipedia.org/wiki/Cartilage">cartilage</a>, <a href="https://en.wikipedia.org/wiki/Ligament">ligaments</a> or any other soft tissues. If required then doctors might replace the knee joint with some instruments. This form of surgery is also widely used for major operations to trace down the position of joint or inner views. It requires a little incision to proceed but only can be prescribed by any qualified surgeons or doctor.</p>
                <p><strong>When should I be aware of total knee replacement?</strong></p>
                <p>Total knee replacement is performed if any other prescribed approaches failed to meet up results. Your doctor is the best person to recommend you a <strong>total knee replacement. </strong>If you are in need to do knee arthroscopy, your doctor should inform you about the activities, pre and post activities, recovery process and other relevant segments. In the developing countries knee arthroscopy is widely practiced because of its &nbsp;&nbsp;effectiveness. A few are as followed:</p>
                <p>&nbsp;</p>
                <ul>
                    <li>This surgery requires small cut to diagnose affected tissues or bones or ligaments. So after certain periods of time the patients can move to their home.</li>
                    <li>It reduces the joint pain with almost no surgical mark on your skin.</li>
                    <li>Every surgery does have their own recovery process so does knee arthroscopy have the same. But comparatively it requires some basic exercise to perform to recover.</li>
                </ul>
                <p>Likewise developing countries, knee arthroscopy is also performed in Bangladesh for past few years. Yes you heard it right. <strong>Hip Knee Center BD(Hyperlink with Homepage: </strong><a href="http://hipkneecenterbd.com/">http://hipkneecenterbd.com</a><strong>)</strong> is offering both hip and knee arthroscopy in the heart of capital. Recently, their success rate is adding new edge to Bangladeshi medical sector. They have highly qualified doctor and multi award receiver like <strong>Dr. AM Farid Uddin Ahmed</strong>. He is serving patients suffering of intolerable hip and knee joint pain with his vast experience and knowledge.</p>
                <p><strong>Hip Knee center</strong> also offers consultation for their patients. So from now on you don&rsquo;t need to put yourself into the hustle of getting visas for scientific treatment at all. All you need to go to the following address:-</p>
                <p><strong>167/B, Green Road, Dhanmondi, Dhaka, Bangladesh. Or you can also set an appointment over phone at: 01686-640449 or Email: <a href="mailto:fuahmed2007@yahoo.com">fuahmed2007@yahoo.com</a></strong></p>
                <p><strong>How Knee Arthroplasty is performed? </strong></p>
                <p>Firstly, the knee surgeon will use anesthesia so that during the surgery you don&rsquo;t feel any pain. Three different types of anesthesia are used for knee arthroplasty.</p>
                <p>Some pain relief medicine is used to numb your knee during surgery. These medicines called local anesthesia. In this process, you will be awake but numb.</p>
                <p>&nbsp;</p>
                <div className="sp-img">
                    <img src="/img/knee2image.jpg" className="img-fluid" alt="Responsive image" />
                </div>
                <p>&nbsp;</p>
                <p>To make you numb surgeon may inject some medicine to your spine. Like local anesthesia, you will be awake but you can&rsquo;t feel anything below your waist. This anesthesia is called regional or spinal anesthesia.</p>
                <p>And the last type of anesthesia is the general anesthesia that normally used to make you asleep during surgery.</p>
                <p>After make you numb, the surgeon will create few small cut accordingly his/her needs. Then he/she will insert or inject saline into those cuts to swell your knee. Then the doctor will insert a arthroscopy from those cuts to see the inner views of your knee joints. After successfully observed, doctor will decide whether to if the issues in your jonts can be solved using arthroscopy tools or not. Normally, arthroplasty or replacement surgery lasts for 1-2 hours. In some cases the operation may take around 45 minutes. After replacing the joint, doctor will push saline again and stitch the cut. Generally, you can move to your home immediately after few hours of the surgery or the same day of your surgery. Before dispatch, your surgeon will instruct you about your recovery process and prescribe you accordingly.</p>
                <p><strong>How long does it take to recover knee Arthroplasty</strong></p>
                <p>Knee arthroscopy recovery is typically faster than recuperating from open surgery. Many people leave the clinic upon the arrival of the activity with explicit directions about how to deal with recuperation. Some Basic guidance includes:</p>
                <ul>
                    <li>applying ice packs to the dressing and encompassing region to diminish expanding and torment</li>
                    <li>Keep the leg raised for a few days after operation.</li>
                    <li>Take proper bed rest as doctor prescribed</li>
                    <li>Routinely change your dressing. If you can&rsquo;t make it properly then go to the doctor.</li>
                    <li>Use bolsters and follows your doctor&rsquo;s instructions to applying weight to the knee.</li>
                </ul>
                <p>&nbsp;</p>
                <p>Doctor will ordinarily give necessary directions before you leave the clinic. They may likewise prescribe painkillers or prescribe <a href="https://www.drugs.com/otc/">OTC medications</a> for torment the executives. Recuperation times can differ. You might move within 1&ndash;3 weeks and continue most other physical exercises within two months.</p>
                <p><strong>Effective Exercises for knee pain Solution</strong></p>
                <p>After knee replacement, exercises are the best way to strengthen back your muscles. Again this also depends on doctor&rsquo;s recommendation. The exercises are basically to strengthen the muscles and help you to get well soon. Exercise includes some stretching task and task that strengthen your muscles. <br /> <br /> You can get some Hip Exercise ideas from here:- <strong>7 Best Hip Exercise: Get Physical Hip Therapies Yourself (Hyperlink with another blog post)</strong></p>
                <p><br /> <br /> Before dispatching, doctor will guide you about your exercises or you can contact with a physician for further assistance. Exercises are the beauty of knee arthroplasty while bigger operation&rsquo;s recovery process requires strong medication.</p>
            </div>
        );
    }
}