import React,{Component} from 'react';
import {Link} from 'react-router-dom';


export default class TestimonialVideo extends Component{
    render(){
        return(
            <div className="testimonial-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                              <div className="carousel-inner">
                                <div className="carousel-item active">
                                      <iframe src="https://www.youtube.com/embed/Z75hR7I-EQc" width="100%" height="315" frameborder="0"></iframe>
                                      {/* <div className="carousel-caption d-md-block">
                                          <h5>Mst. Sokina Khatun</h5>
                                          <span className="pproblem">Female(55), Problem - Hip Pain, Treatment - Hip Replacement</span>
                                    </div>	       */}
                                </div>
                                <div className="carousel-item">
                                      <iframe src="https://www.youtube.com/embed/1oF1vkQWlwE" width="100%" height="315" frameborder="0"></iframe>
                                      {/* <div className="carousel-caption d-md-block">
                                          <h5>Mst. Chameli Khatun</h5>
                                          <span className="pproblem">Female(55), Problem - Hip Pain, Treatment - Hip Replacement</span>
                                    </div>	   	       */}
                                </div>
                                <div className="carousel-item">
                                     <iframe src="https://www.youtube.com/embed/fbt3tSAQxP4" width="100%" height="315" frameborder="0"></iframe>
                                      {/* <div className="carousel-caption d-md-block">
                                          <h5>Mst. Bilkis Khatun</h5>
                                          <span className="pproblem">Female(55), Problem - Hip Pain, Treatment - Hip Replacement</span> 
                                    </div>	    */}
                                </div>
                              </div>
                             <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"><i className="fa fa-chevron-left" aria-hidden="true"></i></span>
                                <span className="sr-only">Previous</span>
                              </a>
                              <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"><i className="fa fa-chevron-right" aria-hidden="true"></i></span>
                                   <span className="sr-only">Next</span>
                              </a>
                        </div>					
                    </div>
                </div>
            </div>
        </div>
        );
    }
}
