import React,{Component} from 'react';
import {Link} from 'react-router-dom';

export default class SocialMedia extends Component{
    render(){
        return(
            <div className="sp-social">
            <span>Share on</span>
            <a href="#" className="fa fa-facebook"></a>
            <a href="#" className="fa fa-twitter"></a>
            <a href="#" className="fa fa-linkedin"></a>
            <a href="#" className="fa fa-google"></a>
        </div>
        );
    }
}