import React,{Component} from 'react';
import {Link} from 'react-router-dom';

export default class AboutUsContent extends Component{
    render(){
        return(
            <div>
                <h2>About Us</h2>
                <p>The Adelaide Hip and Knee Centre is South Australia&rsquo;s only orthopaedic group solely dedicated to the management of disorders of the hip and knee.</p>
                <p>The centre evolved from the previously well respected and established group Adelaide Orthopaedics and Trauma Specialists. Hip and knee surgery is undergoing a constant increase in complexity with ever emerging technology. Our surgeons believe that total dedication to this area allows us to provide our patients with the best level of care utilising the latest evidence based medicine and most up to date techniques and technologies.</p>
                <p>All of our surgeons are highly experienced and have undergone post-fellowship training both nationally and internationally to ensure that the care they provide is of the highest standard.</p>
                <p>The Adelaide Hip and Knee Centre is located at the&nbsp;<a href="https://www.burnsidehospital.asn.au/" target="_blank" rel="noopener">Burnside War Memorial Hospital</a>&nbsp;in Toorak Gardens, with easy access to public transport and onsite parking. Our surgeons also consult at multiple sites throughout South Australia and the Northern territory.</p>
                <p>Our surgeons&nbsp;<a href="https://ahkc.com.au/?team=robert-baird">Dr Robert Baird</a>,&nbsp;<a href="https://ahkc.com.au/?team=robert-fassina">Dr Robert Fassina</a>,&nbsp;<a href="https://ahkc.com.au/?team=justin-munt">Dr Justin Munt</a>,&nbsp;<a href="https://ahkc.com.au/?team=andrew-morris">Dr Andrew Morris</a>&nbsp;and&nbsp;<a href="https://ahkc.com.au/?team=assoc-prof-mark-rickman">A/Prof Mark Rickman&nbsp;</a>specialise in:<br />&ndash; Hip and knee replacement<br />&ndash;&nbsp;<a href="https://ahkc.com.au/robotic-surgery/">Robotic Assisted Surgery</a><br />&ndash; Revision joint replacement<br />&ndash;&nbsp;<a href="https://ahkc.com.au/conditions/paediatric-conditions/">Paediatric orthopaedics</a><br />&ndash;&nbsp;<a href="https://ahkc.com.au/conditions/knees/knee-arthroscopy/">Knee arthroscopy</a><br />&ndash;&nbsp;<a href="https://ahkc.com.au/conditions/hips/hip-arthroscopy/">Hip arthroscopy</a>&nbsp;including joint preservation surgery<br />&ndash; Knee reconstruction</p>
            </div>
        );
    }
}