import React,{Component} from 'react';
import {Link} from 'react-router-dom';

export default class MayLike extends Component{
    render(){
        return(
            <body>
						<div className="sp-related-post">
							<h4>YOU MAY ALSO LIKE</h4>
							<div className="row">
								<div className="col-md-4 sprp-single">
									<img src="/img/hipArthoscopy.jpg" className="img-fluid img-thumbnail" alt="Responsive image" />
									<h6><Link to="/blog/hip-arthroscopy">Ultimate solution for long time painful hip joint</Link></h6>
								</div>
								<div className="col-md-4 sprp-single">
									<img src="/img/hipArthroscopyRecoveryTipsArthroscopySpecialist.jpg" className="img-fluid img-thumbnail" alt="Responsive image" />
									<h6><Link to="/blog/hip-arthroscopy-surgery">Hip Arthroscopy Surgery Process, Benefits & Recovery: Tips by Arthroscopy Specialist</Link></h6>
								</div>
								<div className="col-md-4 sprp-single">
									<img src="/img/exercise.jpg" className="img-fluid img-thumbnail" alt="Responsive image" />
									<h6><Link to="/blog/hip-exercise">7 Best Hip Exercise: Get Physical Hip therapies yourself</Link></h6>
								</div>
							</div>
						</div>
            </body>
        );
    }
}