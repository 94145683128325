import React,{Component} from 'react';
import {Link} from 'react-router-dom';

class Footer extends Component{
    render(){
        return(
      <footer className="main-footer">
       <div className="footer-top">
          <div className="container">
              <div className="row">
                  <div className="offset-4 col-md-4">
                      <div className="social-icon">
                          <a href="https://www.facebook.com/drfaridhipknee/"><i className="fa fa-facebook-f"></i></a>
                          <a href="https://www.youtube.com/channel/UCDbPIaHguW9vBf0-uyj46RA"><i className="fa fa-youtube"></i></a>
                          <a href="https://twitter.com/drfarid3"><i className="fa fa-twitter"></i></a>
                          <a href="https://www.linkedin.com/in/dr-fu-ahmed/"><i className="fa fa-linkedin"></i></a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div className="footer-middle">
          <div className="container">
              <div className="row">
                  <div className="col-md-4">
                      <a href='https://hipkneecenterbd.com'><h3>Hip & Knee Center</h3></a>
                      <p>
                      167/B, Green Road, <br/>
                      Dhanmondi, Dhaka-1209,<br/>
                      Bangladesh
                      </p>
                      <a href="mailto:info@hipkneecenterbd.com">info@hipkneecenterbd.com</a>
                  </div>
                  <div className="col-md-4">
                      <h3>Usefull Links</h3>
                      <ul>
                          <li><Link to="/contact">Contact Us</Link></li>
                          <li><Link to="/aboutus">About Us</Link></li>
                          <li><Link to="/privacypolicy">Privacy Policy</Link></li>
                          <li><Link to="/termsconditions">Terms Conditions</Link></li>
                      </ul>
                  </div>
                  <div className="col-md-4">
                      <h3>Latest Posts</h3>
                      <ul>
                          <li><Link to="/blog/hip-arthroscopy">Hip Arthroscopy</Link></li>
                          <li><Link to="/blog/knee-arthroscopy">Knee Arthroscopy</Link></li>
                          <li><Link to="/blog/hip-arthroscopy-surgery">Hip Arthroscopy Surgery</Link></li>
                          <li><Link to="/blog/hip-exercise">Hip Exercise</Link></li>
                      </ul>
                  </div>
              </div>
              <div className="footer-bottom">
                  <p>Copyright &copy; 2020 <a href='https://hipkneecenterbd.com'>Hipkneecenterbd.com</a> All Rights Reserved</p>
              </div>				
          </div>			
      </div>
  </footer>
        );
    }
}

export default Footer;