import React,{Component} from 'react';
import {Link} from 'react-router-dom';

export default class AppoinmentForm extends Component{
    render(){
        return(
				<div className="col-lg-4 dr-sidebar">
					<div className="ac-card">
						<h4>SCHEDULE AN APPOINTMENT</h4>
						<form>
						  	<div className="form-row">
						    	<div className="form-group col-md-12">
						      		<input type="text" className="form-control" placeholder="First name"/>
						    	</div>
						    	<div className="form-group col-md-12">
						      		<input type="text" className="form-control" placeholder="Last name"/>
						    	</div>
						 	 </div>
						  	<div className="form-group">
						    	<input type="email" className="form-control" id="inputEmail4" placeholder="Email"/>
						  	</div>
				  			<div className="form-group">
					    		<input type="text" className="form-control" placeholder="Phone Number"/>
					  		</div>
					  		<div className="form-group">
						  		<label className="form-check-label">I'm having an issue with my *</label>
								<select className="form-control">
								 	<option>Choose</option>
								 	<option>Hip</option>
								 	<option>Both Hips</option>
								 	<option>Knee</option>
								 	<option>Both Knees</option>
								</select>
							</div>	
							<div className="form-row">
								<div className="form-group col-md-12 mr0">
									<label className="form-check-label">Are You Currently a Patient? *</label>
								</div>	
								<div className="form-group col-md-12">	
									<div className="custom-control custom-radio custom-control-inline">
									  	<input type="radio" id="customRadioInline1" name="customRadioInline1" className="custom-control-input"/>
									  	<label className="custom-control-label" for="customRadioInline1">Yes</label>
									</div>
									<div className="custom-control custom-radio custom-control-inline">
									  	<input type="radio" id="customRadioInline2" name="customRadioInline1" className="custom-control-input"/>
									  	<label className="custom-control-label" for="customRadioInline2">No</label>
									</div>
								</div>	
							</div>
						  	<div className="form-group">
						    	<label for="exampleFormControlTextarea1">Comment or Message</label>
						    	<textarea className="form-control" id="exampleFormControlTextarea1" rows="4"></textarea>
						  	</div>							
						  	<button type="submit" className="btn btn-primary mb-2">Submit</button>
						</form>							
					</div>				
					{/* <div className="drs-sidebar">
						<h3>Our Services</h3>
						<p><a href="#">Anterior Approach Hip Replacement</a></p>
						<p><a href="#">Total Knee Replacement</a></p>
						<p><a href="#">Advantages of Outpatient Surgery</a></p>
						<p><a href="#">Educational Videos</a></p>
					</div> */}
				</div>
        );
    }
}