import React,{Component} from 'react';
import {Link} from 'react-router-dom';

export default class HipSurgeryContent extends Component{
    render(){
        window.scrollTo(0,0);
        return(
            <div>
                <p><strong> What is hip Arthroscopies Surgery?</strong></p>
                <p>Arthroscopy is a new innovation in the field of medical science. This form of surgery is widely practiced in the developed countries. During the surgery a small camera is inserted from the cut make by the surgeon. This small camera is called arthroscope. In according to the name of this little camera the surgery is named of Arthroscopy Surgery. Arthroscopy surgery can be performed in almost every parts of our body but mostly this technique is performed on knee, Hip, Ankle, Elbow, and other joint of our body. Our hip joint is made of several critical bones, tissues.&nbsp; Any abnormality between these bones and tissues causes pain.</p>
                <p>&nbsp;</p>
                <p><strong>Issues lead to arthroscopy surgery</strong></p>
                <p>As mentioned earlier, any abnormality between the bones and tissues that helps our hip move freely can lead arthroscopy surgery. Followings are the frequent issues often results hip arthroscopy:-</p>
                <ul>
                    <li>Femoral head is placed between femur and pelvis. It is also known as ball. The standard size of femoral head is 40-50 mm. so any unexpected increase of the size of this ball may generate pain to our hip joints.</li>
                    <li>Another important part of our hip joint is the acetabulum or also called socket. This is a tiny surface of pelvis where femoral head or ball can easily move. Injuries such as accident or fall from any height may cause acetabulum fracture and this may result abnormal pain to our joints and lead to surgery.</li>
                    <li><a href="https://www.hopkinsmedicine.org/orthopaedic-surgery/specialty-areas/sports-medicine/conditions-we-treat/labral-tear-shoulder.html">Labral tear</a> is a cartilage that stables the movement of femoral head. This cartilage may get affected by traumatic event such as road accidents, fall down, slipped and hit. In some cases, Athletes or during an sport event like cricket, football, hockey player may face labral tear for any inappropriate movements. That can lead hip pain as well.</li>
                    <li>Ligament tear is often caused of excessive pressure on muscles. This issue can affect normal functionality of femur or acetabulum and generate pain.</li>
                </ul>
                <p><strong>Consult your Hip issues with an Arthroplasty surgeon</strong></p>
                <p>Any sort of surgery or operation requires to have consulted with experts. In developed countries hip arthroplasty is very common. So the patients can easily find hip arthroplasty surgeon. But in Bangladesh it is quiet difficult to find one. It&rsquo;s least but not the last. Don&rsquo;t lose your hope as now Bangladesh also has highly qualified hip arthroplasty surgeon. Yes, <strong><a href="https://hipkneecenterbd.com">Hip &amp; Knee Center BD</a></strong> is serving arthroplasty for last few years and became very popular. The prime cause of their success rate is <strong>Dr. AM Farid Uddin Ahmed. </strong>He is the one who do the surgery and consultation. Since he graduated in 1999 from medicine, he perused his <strong>orthopedic certification</strong> from <strong>BCPS</strong> in 2007. He has also completed his MS in orthopedic surgery from <strong>BSMMU </strong>in 2009. He also has multiple national and international recognitions for his outstanding effort and contribution. So, if you are thought of doing hip arthroplasty but worrying about visa and specialist, you should definitely go to <strong>Hip &amp; Knee center. </strong>You can Follow the address given below:-</p>
                <p><strong>Comfort Tower, 1167/B,Green Road, Dhanmondi, Dhaka, Bangladesh. Or confirm your appointment at the following Mobile Number: 01686-640449. You can also Email: <a href="mailto:fuahmed2007@yahoo.com">fuahmed2007@yahoo.com</a></strong></p>
                <p><strong>Opens at: Tuesday-(8 AM-4 PM)</strong></p>
                <p><strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Thursday-(8 AM-11 PM)</strong></p>
                <p><strong>Website: </strong><a href="https://www.hipkneecenterbd.com/">https://www.hipkneecenterbd.com/</a></p>
                <p>&nbsp;</p>
                <p><strong>Hip surgery Preparation</strong></p>
                <p>Alike other surgery hip surgery also needs some basic preparation. Firstly before go for arthroplasty you should be properly aware of the facts of hip arthroscopy. This is the first and foremost responsibility of your surgeon to let you know about the process he is going to follow to cure you. During consultation your doctor must mention you the surgical process and recovery process. After giving you an overview of hip arthroplasty, he will then give you some tests to be done before surgery. These tests are relevant to the surgical process. Doctor will then go through your test reports and evaluate your overall health condition for the surgery. These tests include your weight, your previous medication habit, your skin, your urine. Other than these preparations you can also consult about your post preparation with the surgeon. He will brief you in details of the activities you can do or can&rsquo;t. Basically these are the preparations made for hip replacement.</p>
                <p><strong>Hip Surgery process:</strong></p>
                <p>Doctor will start your surgery by anesthesia. There are 3 types of anesthesia is used for any surgery. Doctor will decide is the best for you. Then the doctor will create a tiny hole on your hip and insert Arthroscope. Then he will observe the condition of your joint. If replacement requires, doctor will replace joints with artificial components. This component varies of cemented and non cemented materials. Finally, doctor will push some liquid saline from that small cut and stitch up. With doctors&rsquo; recommendation after surgery you can move to your home.</p>
                <p>&nbsp;</p>
                <div className="sp-img">
                    <img src="/img/hipSurgery2image.jpg" className="img-fluid" alt="Responsive image" />
                </div>
                <p>&nbsp;</p>
                <p><strong>Hip Replacement Advantages &amp; Recovery:</strong></p>
                <p>Hip replacement or hip arthroplasty is effective yet critical. In compared to its complexity, it requires minimal tissue damage, blood loss, and pain. Immediately patients can walk or carry recommended weights. It also allows patients to move to their daily activities within no time of their surgery.</p>
                <p>The recovery process is very flexible for hip arthroplasty. Your surgeon will assign you some basic PT to strengthen your muscles and tissues. You also may prescribe some <a href="https://www.versusarthritis.org/about-arthritis/treatments/drugs/painkillers-and-nsaids/">Painkillers</a> to feel better. This recovery process may take 10-12 days to make you jump to your daily life but it may take 4-6 moths to fully get out of arthroplasty issues.</p>
                <p><strong>Risk associated with Hip Arthroplasty</strong></p>
                <p>Every surgery is associated with some risks as well. Arthroplasty also has few risks associated with it. Few of them are mentioned below:-</p>
                <ul>
                    <li>During surgery some healthy portion of your joints may get affected. Though it&rsquo;s rare but there is a possibility.</li>
                    <li>Your stitch sides may get infected. Cure of this infection varies upon their placement. Some requires surgery while other requires antibiotic medication.</li>
                    <li>Hip replacement can increase the length of your leg.</li>
                    <li>Blood clot is very common for any surgery.</li>
                </ul>
            </div>
        );
    }
}