import React,{Component} from 'react';
import {BrowserRouter,Route} from 'react-router-dom';

import Home from './components/home';
import Contact from './components/contact';
import Blog from './components/blog'
import Video from './components/video'
import Navigation from './components/navigation';
import Footer from './components/footer';
import Knee from './components/knee';
import Hip from './components/hip';
import HipSurgery from './components/hipsurgery';
import HipExercise from './components/hipexercise';
import AboutUs from './components/aboutus';
import TermsConditions from './components/termsConditions';
import PrivacyPolicy from './components/privacyPolicy';

class App extends Component {
  render(){
    return (
      <BrowserRouter>
        <Navigation/>
          
          <Route exact path = '/' component = {Home} />
          <Route  path = '/contact' component = {Contact} />
          <Route  path = '/blogs' component = {Blog} />
          <Route  path = '/blog/knee-arthroscopy' component = {Knee} />
          <Route  path = '/blog/hip-arthroscopy' component = {Hip} />
          <Route  path = '/blog/hip-arthroscopy-surgery' component = {HipSurgery} />
          <Route  path = '/blog/hip-exercise' component = {HipExercise} />
          <Route  path = '/aboutus' component = {AboutUs} />
          <Route  path = '/termsconditions' component = {TermsConditions} />
          <Route  path = '/privacypolicy' component = {PrivacyPolicy} />
          <Route  path = '/video' component = {Video} />
          
        <Footer/>
      </BrowserRouter>
    );
  }
}

export default App;
