import React,{Component} from 'react';
import{Link} from 'react-router-dom';
import TestimonialVideo from './testimonialvideo';

class Home extends Component {
  render(){
    return (
    <body>
      {/*Home Page Banner*/}
      <div className="home-banner">
        <img src="img/doctorbanner.jpg" className="img-fluid" alt="Banner"/>
        <div className="container left-banner">
        <div className="row">
            <div className="col-md-6 col-xs-6">
                <h2>Get Rid of Hip & Knee Pain <br/> Run Again!</h2>
                <Link to="/contact" className="btn btn-primary btn-lg">Book An Appointment</Link>
            </div>
        </div>
        </div>
      </div>
  
      {/*Basic Info */}
  
      <div className="basic-info clearfix">
          <div className="container">
              <div className="row">
                  <div className="col-md-6 bione">
                      <h3 className="btn btn-primary"><i className="fa fa-medkit"></i><span>Total Hip Replacement Surgery</span></h3>
                  </div>
                  <div className="col-md-6 bitwo">
                      <h3 className="btn btn-primary"><i className="fa fa-stethoscope"></i><span>Total Knee Replacement Surgery</span></h3>
                  </div>
              </div>
          </div>
      </div>
  
      {/*Our Service Part */}
  
      <div className="service-part">
          <div className="container">
              <h2 className="text-center">Our Services</h2>
              <div className="row">
                  <div className="col-md-4">
                      <div className="single-service">
                          <img src="img/Hip2.png" className="img-fluid" />
                          <h4>Hip & Knee Pain Relief Treatment & Arthroplasty</h4>
                          <p>For some low worries cases, we suggest our patients taking pills, using solutions, and exercise regularly to get relief from long time pain at their knee or hip joint. This is a quite popular method by last decade Arthroscopy specialist prescribing as an alternative to Hip or Knee Arthroplasty & Open Surgery. Many Doctors suggest their patient this proven method to alleviate pain and improve mobility in their hip or knee joints.</p>						
                      </div>
                  </div>
                  <div className="col-md-4">
                      <div className="single-service">
                          <img src="img/Nee.png" className="img-fluid" />
                          <h4>Total Knee Replacement & Arthroplasty</h4>
                          <p>if you have a long time pain at your Knee Joint then you can meet our Professional Arthroscopy Surgeon. After a few observations, they will suggest you the best way to get relief from such long time Knee Pain. Sometimes you need Total Knee Replacement Surgery to get rid of Knee Joints Problem. We have a good record of successful Hip Replacement Operation from the last decade. Recovering from arthroscopy is usually quicker than recovering from open surgery.</p>						
                      </div>
                  </div>
                  <div className="col-md-4">
                      <div className="single-service">
                          <img src="img/Hip.png" className="img-fluid" />
                          <h4>Total Hip Replacement & Arthroplasty</h4>
                          <p>In this era, it’s no more exception to meet people who had back from Hip Surgery Center to get rid of long-time Hip pain. Millions of People Living a Healthy life worldwide even after a Total Hip Replacement Surgery. We ensure the Updated Technology for Hip Arthroplasty and also at Reasonable Price. Our Professional Surgeons will surgically remove your painful hip joint with arthritis and replaces it with an artificial joint. We help you by making walking easy again!</p>						
                      </div>
                  </div>
              </div>
          </div>
      </div>
  
      {/*Doctor's Part Design */}
      <div className="doctors-heading">
          <div className="container">
              <div className="row">
                  <div className="col-md-12">
                      <h3>Doctor's Introduction</h3>
                  </div>
              </div>
          </div>
      </div>
      <div className="doctors-part">
          <div className="container">
              <div className="row single-video">
                  <div className="col-lg-6">
                      {/* <img src="img/doctor-img.jpg" className="img-fluid" alt="Doctors Name"/> */}
                      <iframe src="https://www.youtube.com/embed/IohzhvUpVSc" width="100%" height="415" frameborder="0"></iframe>
                  </div>
                  <div className="col-lg-6">
                      <div className="doctor-des">
                          <h4>Dr. AM Farid Uddin Ahmed</h4>
                          <span>MBBS, FCPS (General Surgery), MS Ortho (NITOR)</span><br/>
                          <b>Orthroplasty and Orthroscopy Surgeon</b>
                      </div>	
                      <div className="doctor-quote">
                          <span>Never Stop Moving</span>
                          <p>Having graduated from Medicine in 1999, Dr. A. M. Forid Uddin Ahmed has pursued his career in the field of orthopedics and completed his fellowship in general surgery in 2007 from Bangladesh College of Physicians & Surgeons (BCPS) & MS in orthopaedic surgery from National Institute of Traumatology and Orthopedic Rehabilitation under Bangabandhu Sheikh Mujib Medical University (BSMMU) in 2009. Throughout carrier, he has relentlessly devoted his time and efforts to serve the orthopedic surgery.</p>
                      </div>												
                  </div>
              </div>
          </div>
      </div>
  
  
      {/*Choosing Reason */}
  
      <div className="choose-us-heading">
          <div className="container">
              <div className="row">
                  <div className="col-md-12">
                      <h3>WHY CHOOSE US</h3>
                  </div>
              </div>
          </div>		
      </div>
  
      <div className="choose-us">
          <div className="container">
              <div className="row">
                  <div className="col-md-12">
                      <div className="accordion" id="accordionExample">
                            <div className="card">
                              <div className="card-header" id="headingOne">
                                    <h2 className="mb-0">
                                      <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">24 Hours Service</button>
                                    </h2>
                              </div>
  
                              <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div className="card-body">
                                    We have Dedicated online support to respond to your quires anytime related to our service, price, and consults. So feel free to mail our online support team with any of your queries. Official Mail Address>> fuahmed2007@yahoo.com 
                                    </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header" id="headingTwo">
                                    <h2 className="mb-0">
                                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Medical Consulting</button>
                                    </h2>
                              </div>
                              <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div className="card-body">
                                    Our Doctors are friendly and they like to hear from you. We have an online free medical Consulting Team active 24/7 hours to meet your quires related to our service and medical consultation. You can write to us your present condition and symptoms anytime. Arthroscopy Specialist will analyze and send you a solution or preparation for Total replacement Surgery.
                                    </div>
                              </div>
                           </div>
                            <div className="card">
                              <div className="card-header" id="headingThree">
                                    <h2 className="mb-0">
                                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Care with Love</button>
                                    </h2>
                              </div>
                              <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div className="card-body">
                                    HipKneeCenterBd.Com consists of a Dedicated team of Professional Arthroscopy Surgeons and expert Doctors to help you get rid of your long-time Keen or Hip pain. We do value your wellness and try our best to cure you faster. So don’t worry, come to us and get a permanent solution to your Knee and Hip related Problem.
                                    </div>
                              </div>
                           </div>
                            <div className="card">
                              <div className="card-header" id="headingFour">
                                    <h2 className="mb-0">
                                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">Humble Stuff</button>
                                    </h2>
                              </div>
                              <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                    <div className="card-body">
                                    The Hipkneecenterbd.com is a Team of dedicated Arthroscopy surgeons and humble staffs for online support. You can hit us anytime with any confusion regarding our service. No matter it is midnight or the weekend. You will get a response from us within an hour.
                                    </div>
                              </div>
                           </div>
                            <div className="card">
                              <div className="card-header" id="headingFive">
                                    <h2 className="mb-0">
                                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">Reasonable Billing</button>
                                    </h2>
                              </div>
                              <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                    <div className="card-body">
                                    Our motto is - “Getting Medical Support is Human right”. This is the reason why poor and unable people can get medical support from us at an affordable price. You just need to send a simple application describing your condition and why we should make it affordable for you by describing only 3 reasons. After acceptance by the CEO of HipKneeCenterBd.Com, we will grand you a smart discount for Total Hip or knee Surgery or related treatment. Send your Application to this mail>> fuahmed2007@yahoo.com

                                    </div>
                              </div>
                           </div>
                            <div className="card">
                              <div className="card-header" id="headingSix">
                                    <h2 className="mb-0">
                                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">Qualified Doctors</button>
                                    </h2>
                              </div>
                              <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                                    <div className="card-body">
                                    Before you lay down on operation theatre, it always matters to you- if the surgeon doesn’t have enough experience on Total replacement Surgery. The question may arise- Is the Surgeon professional on arthroscopy Surgery? No one wants a newbie there, as the rate of unsuccessful operation will increase. But Here @HipKneeCenterBd we have Specialist Surgeon on the Arthroscopy & Arthroplasty. They have 10+ years of expertise in the Related Sector. They ensure to meet all requirements towards a successful hip & knee surgery.
                                    </div>
                              </div>
                           </div>
                      </div>				
                  </div>
              </div>
          </div>
      </div>
  
      {/*Testimonial Heading Section */}
      <div className="testimonial-heading">
          <div className="container">
              <div className="row">
                  <div className="col-md-12">
                      <h3>Patient Review About Our Care</h3>
                  </div>
              </div>
          </div>
      </div>	
  
      {/*Testimonial Section */}
        <TestimonialVideo />
    </body>
    );
  }
  
}

export default Home;
