import React,{Component} from 'react';

class Video extends Component{
    render(){
        return(
  <body>
	{/* Video Section */}
	<div className="video-section">
		<div className="container">
			<div className="row">
				<div className="col-md-12 video-top">
					<h2>Educational Videos</h2>
					<p>Here you will find short, informational videos by Dr. Shawn W. Palmer, covering the most common questions asked by new patients.  Learn about The Hip & Knee Center, Anterior Approach Hip Replacement surgery, Total Knee Replacement surgery and more.</p>
				</div>
			</div>			
			<div className="row single-video">
				<div className="col-md-6">
					<h4>Meet Dr. Shawn W. Palmer</h4>
					<iframe src="https://www.youtube-nocookie.com/embed/KSgR29x_I5Y?loop=1&modestbranding=1&showinfo=0&rel=0&cc_load_policy=1&iv_load_policy=3&theme=light&fs=0&color=white&autohide=0&controls=0&disablekb=1" width="100%" height="315" frameborder="0"></iframe>
				</div>				
				<div className="col-md-6 mmar">
					<h4>Meet Dr. Shawn W. Palmer</h4>
					<iframe src="https://www.youtube-nocookie.com/embed/KSgR29x_I5Y?loop=1&modestbranding=1&showinfo=0&rel=0&cc_load_policy=1&iv_load_policy=3&theme=light&fs=0&color=white&autohide=0&controls=0&disablekb=1" width="100%" height="315" frameborder="0"></iframe>
				</div>
			</div>			
			<div className="row single-video">
				<div className="col-md-6">
					<h4>Meet Dr. Shawn W. Palmer</h4>
					<iframe src="https://www.youtube-nocookie.com/embed/KSgR29x_I5Y?loop=1&modestbranding=1&showinfo=0&rel=0&cc_load_policy=1&iv_load_policy=3&theme=light&fs=0&color=white&autohide=0&controls=0&disablekb=1" width="100%" height="315" frameborder="0"></iframe>
				</div>				
				<div className="col-md-6 mmar">
					<h4>Meet Dr. Shawn W. Palmer</h4>
					<iframe src="https://www.youtube-nocookie.com/embed/KSgR29x_I5Y?loop=1&modestbranding=1&showinfo=0&rel=0&cc_load_policy=1&iv_load_policy=3&theme=light&fs=0&color=white&autohide=0&controls=0&disablekb=1" width="100%" height="315" frameborder="0"></iframe>
				</div>
			</div>			
			<div className="row single-video">
				<div className="col-md-6">
					<h4>Meet Dr. Shawn W. Palmer</h4>
					<iframe src="https://www.youtube-nocookie.com/embed/KSgR29x_I5Y?loop=1&modestbranding=1&showinfo=0&rel=0&cc_load_policy=1&iv_load_policy=3&theme=light&fs=0&color=white&autohide=0&controls=0&disablekb=1" width="100%" height="315" frameborder="0"></iframe>
				</div>				
				<div className="col-md-6 mmar">
					<h4>Meet Dr. Shawn W. Palmer</h4>
					<iframe src="https://www.youtube-nocookie.com/embed/KSgR29x_I5Y?loop=1&modestbranding=1&showinfo=0&rel=0&cc_load_policy=1&iv_load_policy=3&theme=light&fs=0&color=white&autohide=0&controls=0&disablekb=1" width="100%" height="315" frameborder="0"></iframe>
				</div>
			</div>					
		</div>
	</div>
  </body>
        );
    }
}

export default Video;