import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import HipContent from './hipcontent';

export default class Hip extends Component{
    render(){
        return(
<body>
	{/* Doctor Part */}
	<div className="doctor-part">
		<div className="container">
			<div className="row">
				{/* Main Blog Section */}
				<div className="col-lg-8">
					<div className="single-blog">
						{/* BreadCrumb */}	
						<nav aria-label="breadcrumb" className="hidden-sm-xs">
						  	<ol className="breadcrumb">
						    	<li className="breadcrumb-item"><Link to="/blogs">Blog</Link></li>
						    	<li className="breadcrumb-item active" aria-current="page">Ultimate solution for long time painful hip joint</li>
						  	</ol>
						</nav>	
						{/*Post Title & Meta*/}
						<h2>Ultimate solution for long time painful hip joint</h2>
						<div className="mb-singlepost-meta">
							<span className="mbs-author">Posted by <Link to="/">HipKneeCenter</Link></span>
							<span className="mb-cat"><Link to="/blog/hip-arthroscopy">Hip Arthroscopy</Link></span>
							{/* <span>October 10, 2019</span> */}
						</div>
						<div className="sp-img">
							<img src="/img/hipArthoscopy.jpg" className="img-fluid" alt="Responsive image" />
						</div>
						<div className="sp-content">
							<HipContent />
						</div>
						<div className="sp-social">
							<span>Share on</span>
							<a href="#" className="fa fa-facebook"></a>
							<a href="#" className="fa fa-twitter"></a>
							<a href="#" className="fa fa-linkedin"></a>
							<a href="#" className="fa fa-google"></a>
						</div>
						<div className="sp-related-post">
							<h4>YOU MAY ALSO LIKE</h4>
							<div className="row">
								<div className="col-md-4 sprp-single">
									<img src="/img/kneeArthoscopy.jpg" className="img-fluid img-thumbnail" alt="Responsive image" />
									<h6><Link to="/blog/knee-arthroscopy">Knee Arthroscopy Preparation & Benefits: Get Relief From Long Time Pain</Link></h6>
								</div>
								<div className="col-md-4 sprp-single">
									<img src="/img/hipArthroscopyRecoveryTipsArthroscopySpecialist.jpg" className="img-fluid img-thumbnail" alt="Responsive image" />
									<h6><Link to="/blog/hip-arthroscopy-surgery">Hip Arthroscopy Surgery Process, Benefits & Recovery: Tips by Arthroscopy Specialist</Link></h6>
								</div>
								<div className="col-md-4 sprp-single">
									<img src="/img/exercise.jpg" className="img-fluid img-thumbnail" alt="Responsive image" />
									<h6><Link to="/blog/hip-exercise">How to run without destroying your knees</Link></h6>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* Blog Page Sidebar Section */}
				<div className="col-lg-4 dr-sidebar">
					<div className="ac-card">
						<h4>SCHEDULE AN APPOINTMENT</h4>
						<form>
						  	<div className="form-row">
						    	<div className="form-group col-md-12">
						      		<input type="text" className="form-control" placeholder="First name"/>
						    	</div>
						    	<div className="form-group col-md-12">
						      		<input type="text" className="form-control" placeholder="Last name"/>
						    	</div>
						 	 </div>
						  	<div className="form-group">
						    	<input type="email" className="form-control" id="inputEmail4" placeholder="Email"/>
						  	</div>
				  			<div className="form-group">
					    		<input type="text" className="form-control" placeholder="Phone Number"/>
					  		</div>
					  		<div className="form-group">
						  		<label className="form-check-label">I'm having an issue with my *</label>
								<select className="form-control">
								 	<option>Choose</option>
								 	<option>Hip</option>
								 	<option>Both Hips</option>
								 	<option>Knee</option>
								 	<option>Both Knees</option>
								</select>
							</div>	
							<div className="form-row">
								<div className="form-group col-md-12 mr0">
									<label className="form-check-label">Are You Currently a Patient? *</label>
								</div>	
								<div className="form-group col-md-12">	
									<div className="custom-control custom-radio custom-control-inline">
									  	<input type="radio" id="customRadioInline1" name="customRadioInline1" className="custom-control-input"/>
									  	<label className="custom-control-label" for="customRadioInline1">Yes</label>
									</div>
									<div className="custom-control custom-radio custom-control-inline">
									  	<input type="radio" id="customRadioInline2" name="customRadioInline1" className="custom-control-input"/>
									  	<label className="custom-control-label" for="customRadioInline2">No</label>
									</div>
								</div>	
							</div>
						  	<div className="form-group">
						    	<label for="exampleFormControlTextarea1">Comment or Message</label>
						    	<textarea className="form-control" id="exampleFormControlTextarea1" rows="4"></textarea>
						  	</div>							
						  	<button type="submit" className="btn btn-primary mb-2">Submit</button>
						</form>							
					</div>				
					{/* <div className="drs-sidebar">
						<h3>Our Services</h3>
						<p><a href="#">Anterior Approach Hip Replacement</a></p>
						<p><a href="#">Total Knee Replacement</a></p>
						<p><a href="#">Advantages of Outpatient Surgery</a></p>
						<p><a href="#">Educational Videos</a></p>
					</div> */}
				</div>
			</div>		
		</div>
	</div>
  </body>
        );
    }
}
